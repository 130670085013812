import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hey, I'm a Computer Science student at the University of Texas at Austin (hook 'em).
I love traveling, learning languages, and playing the piano! Let me know and we'll play a game of ping pong sometime.`}</p>
    <p>{`I have experience in full-stack web development, particularly with React.js and Node.js (Express).
I have built all sorts of applications, including server-side rendered applications, single-page applications that leverage RESTful APIs, and even real-time web services that use websockets.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      