import { jsx, useColorMode } from "theme-ui"




type Props = {
  isDark: boolean
  toggle: (e: any) => void
}

// Adapted from: https://codepen.io/aaroniker/pen/KGpXZo and https://github.com/narative/gatsby-theme-novela/blob/714b6209c5bd61b220370e8a7ad84c0b1407946a/%40narative/gatsby-theme-novela/src/components/Navigation/Navigation.Header.tsx

const ColorModeToggle = ({ isDark, toggle }: Props) => {
  const [colorMode, setColorMode] = useColorMode();
  setColorMode(`dark`);
  return null;
};

export default ColorModeToggle; 